<template>
 <div class="mb-6 border-bottom pb-4">
   <v-row dense >
     <v-col col="12" md="3" lg="3" v-if="hasChildVenues">
       <label>Select Venue</label>
       <v-select
           v-model="benefit.venue_id"
           :items="venues"
           item-text="name"
           item-value="id"
           outlined
           class="q-autocomplete shadow-0"
           dense
           @change="venueChange(),changeEvent('venue_id')"
           hide-details
       ></v-select>

     </v-col>
     <v-col cols="12" md="3">
       <label>Select Module</label>
       <v-select
           v-model="benefit.product_type_id"
           :items="product_types"
           item-text="name"
           item-value="id"
           outlined
           class="q-autocomplete shadow-0"
           dense
           @change="moduleChange"
           hide-details
       ></v-select>

     </v-col>

     <v-col cols="12" md="6">
       <div>
         <label>Select Package Type</label>
         <v-radio-group class="benefit_type_radio"
                        v-model="benefit.is_specific"
                        @change="specificationChange"
                        row
         >
           <v-radio
               label="General"
               :value="0"
               class="custom-radio"
               color="red"
           />
           <v-radio
               label="Specific"
               :value="1"
               class="custom-radio"
               color="red"
           />
         </v-radio-group>
       </div>

     </v-col>
   </v-row>
   <v-row dense >
     <v-col :md="benefit.is_specific == 1 ? 2 : 3">
       <label>Service*</label>
       <v-autocomplete
           v-model="benefit.venue_service_ids"
           :items="benefit.venue_services"
           item-text="name"
           item-value="venue_service_id"
           @change="getBenefitFilter"
           :multiple="benefit.is_specific == 0"
           outlined
           :rules="
              benefit.is_specific == 0
                ? [(v) => !!v[0] || 'Venue service is required']
                : [(v) => !!v || 'Venue service is required']
            "

           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="
                benefit.is_specific == 0 &&
                  benefit.venue_service_ids.length == benefit.venue_services.length
              "
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All Services</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item v-if="benefit.is_specific == 0">
           <v-list-item
               ripple
               @click="toggleVenueServices('venue_service_ids')"
           >
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.venue_service_ids.length > 0
                        ? 'indigo darken-4'
                        : ''
                    "
               >{{ getIconVenueServices("venue_service_ids") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         v-if="product_type.name == 'Event'"
         :md="benefit.is_specific == 1 ? 2 : 4"
     >
       <label>{{product_type.name + 's' + (benefit.is_specific == 1 ? '*' : '')}}</label>

       <v-autocomplete
           v-model="benefit.event_ids"

           :items="mainFilter"
           @change="changeEvent('event_ids')"
           item-text="name"
           item-value="id"
           multiple
           outlined
           required
           :rules="
              benefit.is_specific == 1 ? [(v) => !!v[0] || 'Select events'] : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.event_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All {{ product_type.name + "s" }}</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="toggle('event_ids', 'mainFilter')">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.event_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("event_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         v-if="product_type.name == 'Academy'"
         :md="benefit.is_specific == 1 ? 2 : 4"
     >
       <label>{{ product_type.name + 's' + (benefit.is_specific == 1 ? '*' : '')}}</label>
       <v-autocomplete
           v-model="benefit.workshop_ids"

           :items="mainFilter"
           @change="changeEvent('workshop_ids')"
           item-text="name"
           item-value="id"
           multiple
           outlined
           required
           :rules="
              benefit.is_specific == 1
                ? [(v) => !!v[0] || 'Select workshops']
                : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.event_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0">All {{ product_type.name + "s" }}</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="toggle('workshop_ids', 'mainFilter')">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.event_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("workshop_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         :md="benefit.is_specific == 1 ? 2 : 4"
         v-if="product_type.name == 'Facility'"
     >
       <label>Categories {{benefit.is_specific == 1 ? '*' : ''}}</label>
       <v-autocomplete
           v-model="benefit.category_ids"
           :items="mainFilter"
           @change="changeEvent('category_ids')"
           item-text="name"
           item-value="id"
           required
           outlined
           multiple
           :rules="
              benefit.is_specific == 1
                ? [(v) => !!v || 'Categories is required']
                : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.category_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All Categories</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name}}</span>
           <span v-if="index == 1">, ...</span>

         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="toggle('category_ids', 'mainFilter')">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.category_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("category_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         :md="benefit.is_specific == 1 ? 2 : 4"
         v-if="product_type.name == 'Trainer'"
     >
       <label>Trainers{{benefit.is_specific == 1 ? '*' : ''}}</label>
       <v-autocomplete
           v-model="benefit.trainer_ids"
           :items="mainFilter"
           @change="changeEvent('trainer_ids')"
           item-text="name"
           item-value="id"
           required
           outlined
           multiple
           :rules="
              benefit.is_specific == 1
                ? [(v) => !!v || 'Trainers is required']
                : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.trainer_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All Trainers</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="toggle('trainer_ids', 'mainFilter')">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.trainer_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("trainer_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         :md="benefit.is_specific == 1 ? 2 : 4"
         v-if="product_type.name == 'Membership'"
     >
       <label>Memberships {{benefit.is_specific == 1 ? '*' : ''}}</label>
       <v-autocomplete
           v-model="benefit.membership_ids"
           :items="mainFilter"
           item-text="name"
           @change="changeEvent('membership_ids')"
           item-value="id"
           required
           multiple
           outlined
           :rules="
              benefit.is_specific == 1 ? [(v) => !!v || 'Type is required'] : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.membership_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All Memberships</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item
               ripple
               @click="toggle('membership_ids', 'mainFilter')"
           >
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.membership_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("membership_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col
         v-if="product_type.name == 'Outlet'"
         :md="benefit.is_specific == 1 ? 2 : 4"
     >
       <label>{{product_type.name + 's' + (benefit.is_specific == 1 ? '*' : '')}}</label>
       <v-autocomplete
           v-model="benefit.outlet_ids"
           :items="mainFilter"
           @change="changeEvent('outlet_ids')"
           item-text="name"
           item-value="id"
           multiple
           outlined
           required
           :rules="
              benefit.is_specific == 1
                ? [(v) => !!v[0] || 'Select Outlets']
                : []
            "
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template
             v-if="benefit.outlet_ids.length == mainFilter.length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All {{ product_type.name + "s" }}</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="toggle('outlet_ids', 'mainFilter')">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.outlet_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ getIcon("outlet_ids", "mainFilter") }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col md="3" v-if="benefit.is_specific == 1">
       <label>Products*</label>
       <v-autocomplete
           v-model="benefit.product_ids"
           :items="getProducts()"
           multiple
           @change="changeEvent('product_ids')"
           item-text="name"
           item-value="id"
           required
           outlined
           :rules="[(v) => v.length > 0 || 'Product is required']"
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       >
         <template v-slot:item="{ active, item, attrs, on }">
           <v-list-item
               two-line
               v-on="on"
               v-bind="attrs"
               #default="{ active }"
           >
             <v-list-item-action>
               <v-checkbox :input-value="active"></v-checkbox>
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title class="text-elepsis">
                 {{ item.name }}
               </v-list-item-title>
               <v-list-item-subtitle >
                 {{ item.main }}
               </v-list-item-subtitle>
             </v-list-item-content>
           </v-list-item>
         </template>
         <template
             v-if="benefit.product_ids.length == getProducts().length"
             v-slot:selection="{ index }"
         >
           <span v-if="index == 0" class="text-elepsis">All Products</span>
         </template>
         <template v-else v-slot:selection="{ item, index }">
           <span v-if="index == 0" class="text-elepsis">{{ item.name }}</span>
           <span v-if="index == 1">, ...</span>
         </template>
         <template v-slot:prepend-item>
           <v-list-item ripple @click="productToggle()">
             <v-list-item-action>
               <v-icon
                   :color="
                      benefit.product_ids.length > 0 ? 'indigo darken-4' : ''
                    "
               >{{ productIcon() }}</v-icon
               >
             </v-list-item-action>
             <v-list-item-content>
               <v-list-item-title>All</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </template>
       </v-autocomplete>
     </v-col>
     <v-col md="2">
       <label>Benefit Type*</label>

       <v-select
           required
           :rules="[(v) => !!v || 'Benefit Type is required']"
           v-model="benefit.benefit_type"
           outlined
           :menu-props="{ bottom: true, offsetY: true }"
           @change="changeEvent('benefit_type')"
           item-value="id"
           item-text="name"
           :items="[
              { id: 'P', name: 'Percentage' },
              { id: 'A', name: 'Amount' },
            ]"
           hide-details="auto"
           dense
           class="q-autocomplete shadow-0"
           validate-on-blur
       ></v-select>
     </v-col>
     <v-col md="2">
       <label>{{ benefit.benefit_type == 'A'
           ? 'Amount'
           : benefit.benefit_type == 'P'
               ? 'Percentage*'
               : 'Promotion*'}}</label>
       <v-text-field
           @change="changeEvent('benefit')"
           required
           :rules="
              benefit.benefit_type == 'A'
                ? [
                    (v) => !!v || 'Amount is required',
                    (v) => !isNaN(v) || 'Amount must be a number',
                  ]
                : benefit.benefit_type == 'P'
                ? [
                    (v) => !!v || 'Percentage is required',
                    (v) => !isNaN(v) || 'Amount must be a number',
                    (v) => v <= 100 || 'Percentage should be less than 100',
                  ]
                : [(v) => !!v || 'Promotion is required']
            "
           :prefix="benefit.benefit_type == 'A' ? currencyCode : ''"
           :suffix="benefit.benefit_type == 'P' ? '%' : ''"
           v-model="benefit.benefit"
           outlined
           hide-details="auto"
           dense
           class="q-text-field shadow-0"
           validate-on-blur
       ></v-text-field>
     </v-col>
     <v-col md="1">
       <div class="d-flex mt-6 gap-x-2">
         <v-tooltip bottom >
           <template v-slot:activator="{ on, attrs }">
             <DeleteIcon  @click="deleteBenefit"    v-bind="attrs" style="cursor: pointer"
                          v-on="on"/>

           </template>
           <span>Delete benefit</span>
         </v-tooltip>
         <v-tooltip bottom>
           <template v-slot:activator="{ on, attrs }">
             <CopyIcon  @click="duplicateBenefit" v-bind="attrs" style="cursor: pointer"
                          v-on="on"/>
           </template>
           <span>Duplicate row</span>
         </v-tooltip>
       </div>
     </v-col>
   </v-row>
 </div>
</template>

<script>
import DeleteIcon from '@/assets/images/misc/delete-bg-icon.svg';
import CopyIcon from '@/assets/images/misc/copy-bg-icon.svg';

export default {
  components:{

    DeleteIcon,
    CopyIcon,
  },

  props: {
    hasChildVenues:{type:Boolean, default:false},
    venues:{type:Array, default: () =>[]},
    params: {
      type: Object,
      default: () => {
        return { venue_service_ids: [], is_specific: null };
      },
    },
    product_type: {
      type: Object,
      default: () => {
        return { name: null, id: null };
      },
    },
    benefitIndex: { type: Number },
    packageIndex: { type: Number, default: null },
    durationIndex: { type: Number, default: null },
    source: { type: String, default: null },
    productTypes: { type: Array, default: null },
  },



  watch: {
    params: {
      immediate: true,
      async handler(val) {
        if (val.venue_service_ids && val.is_specific != null) {
          if (
            this.benefit.venue_service_ids == val.venue_service_ids &&
            val.is_specific == this.benefit.is_specific
          )
            return;
          this.benefit.venue_service_ids = val.venue_service_ids;
          this.benefit.is_specific = val.is_specific;

          if (val.venue_id) {
            this.benefit.venue_id = val.venue_id;
            await this.venueChange();
          }
          if (val.category_ids) {
            this.benefit.category_ids = val.category_ids;
          }
          if (val.category_ids) {
            this.benefit.category_ids = val.category_ids;
          }
          if (val.membership_ids) {
            this.benefit.membership_ids = val.membership_ids;
          }
          if (val.trainer_ids) {
            this.benefit.trainer_ids = val.trainer_ids;
          }
          if (val.event_ids) {
            this.benefit.event_ids = val.event_ids;
          }
          if (val.workshop_ids) {
            this.benefit.workshop_ids = val.workshop_ids;
          }
          if (val.product_ids) {
            this.benefit.product_ids = val.product_ids;
          }
          if (val.benefit_type) {
            this.benefit.benefit_type = val.benefit_type;
          }
          if (val.product_type_id) {
            this.benefit.product_type_id = val.product_type_id;
          }
          if (val.benefit) {
            this.benefit.benefit = val.benefit;
          }
          if (val.outlet_ids) {
            this.benefit.outlet_ids = val.outlet_ids;
          }
          setTimeout(() => {
            this.getBenefitFilter();
          });
        }
      },
    },
  },
  data() {
    return {
      onLoad: true,
      product_types:[],
      benefit: {
        venue_id:null,
        is_specific: null,
        venue_service_ids: null,
        venue_services:[],
        benefit_type: null,
        benefit: null,
        category_ids: [],
        trainer_ids: [],
        membership_ids: [],
        event_ids: [],
        workshop_ids: [],
        product_ids: [],
        outlet_ids: [],
        product_type_id:null,
      },
      mainFilter: [],
      products: [],
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if(!this.hasChildVenues){
      this.product_types = this.productTypes;
      this.benefit.venue_services = this.venueServices;
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    getBenefitFilter() {
      this.changeEvent("venue_service_ids");
      let urlParams = `?product_type=${this.product_type.name}&is_specific=${this.benefit.is_specific}`;
      if (this.benefit.is_specific == 0) {
        if (this.benefit.venue_service_ids.length == 0) {
          return;
        }
        this.benefit.venue_service_ids.map((vsId, index) => {
          urlParams += `&venue_service_ids[${index}]=${vsId}`;
        });
      } else {
        if (this.benefit.venue_service_ids == null) {
          return;
        }
        urlParams += `&venue_service_ids[0]=${this.benefit.venue_service_ids}`;
      }
      if (this.onLoad) {
        this.onLoad = false;
      } else {
        this.benefit.product_ids = [];
      }
      this.showLoader("Loading");
      this.$http
        .get(`venues/benefits/filter${urlParams}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.mainFilter = data.main;


            let ids = this.mainFilter.map(element => element.id);
            let category_ids = this.benefit.category_ids;

            let intersection = ids.filter(id => category_ids.includes(id));

            // console.log(ids,'ids');
            // console.log(category_ids,'category_ids');
            // console.log(intersection,'intersection');

            this.benefit.category_ids = intersection;
            this.changeEvent('category_ids');

            let select = [];
            if (data.products && data.products.length) {
              let type = data.products[0].main;
              select.push({ header: type.toUpperCase() });
              select.push({ divider: true });
              data.products.forEach((product) => {
                if (product.main != type) {
                  select.push({ divider: true });
                  select.push({ header: product.main.toUpperCase() });
                  select.push({ divider: true });
                  type = product.type;
                }
                select.push(product);
              });
            }
            this.products = select;
            this.$forceUpdate();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    duplicateBenefit() {
      this.$emit("duplicate", {
        benefitIndex: this.benefitIndex,
        packageIndex: this.packageIndex,
        durationIndex: this.durationIndex,
      });
    },
    deleteBenefit() {
      this.$emit("delete", {
        benefitIndex: this.benefitIndex,
        packageIndex: this.packageIndex,
        durationIndex: this.durationIndex,
      });
    },

    changeEvent(field) {
      this.$emit("update", {
        field: field,
        data: this.benefit[field],
        index: {
          benefitIndex: this.benefitIndex,
          packageIndex: this.packageIndex,
          durationIndex: this.durationIndex,
        },
      });
    },
    async venueChange(){
      this.product_types = [];
      this.mainFilter=[];
      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/get-venue-product-types/${this.benefit.venue_id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let permissions = response.data.data.permissions;
              this.product_types = Object.values(permissions)
                  .filter(permission => permission.allowed === 1)
                  .map(permission => ({
                    id: permission.id,
                    name: permission.name
                  }));
              this.benefit.venue_services = response.data.services;

              console.log(this.product_types,'this.product_types')
            }else{
              this.product_types = [];
              this.benefit.venue_services = [];
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },
    moduleChange(){
      if (this.benefit.product_type_id != null) {
        this.changeEvent("product_type_id");
        if (this.benefit.is_specific != null) {
          if (this.benefit.is_specific == 0) {
            this.benefit.venue_service_ids = [];
          } else {
            this.benefit.venue_service_ids = null;
          }
          this.changeEvent("is_specific");
          this.changeEvent("venue_service_ids");
        }
        this.mainFilter=[];
      }
    },
    specificationChange() {
      if (this.benefit.is_specific != null) {
        if (this.benefit.is_specific == 0) {
          this.benefit.venue_service_ids = [];
        } else {
          this.benefit.venue_service_ids = null;
        }
        this.changeEvent("is_specific");
        this.changeEvent("venue_service_ids");
      }
    },
    toggle(type, dataField) {
      this.$nextTick(() => {
        if (this.benefit[type].length == this[dataField].length) {
          this.benefit[type] = [];
          setTimeout(() => {
            this.changeEvent(type);
          });
        } else {
          if (dataField == "venueServices") {
            this.benefit[type] = this[dataField].map(
              (item) => item.venue_service_id
            );
            setTimeout(() => {
              this.getBenefitFilter();
            });
          } else {
            this.benefit[type] = this[dataField].map((item) => item.id);
            setTimeout(() => {
              this.changeEvent(type);
            });
          }
        }
      });
    },
    toggleVenueServices(type) {
      this.$nextTick(() => {
            this.benefit[type] = this.benefit.venue_services.map(
              (item) => item.venue_service_id
            );
            setTimeout(() => {
              this.getBenefitFilter();
            });
      });
    },
    getIconVenueServices(type) {
      if (this.benefit[type].length == this.benefit.venue_services.length)
        return "mdi-close-box";
      if (
          this.benefit[type].length > 0 &&
          this.benefit[type].length != this.benefit.venue_services.length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    productToggle() {
      this.$nextTick(() => {
        if (this.benefit.product_ids.length == this.getProducts().length) {
          this.benefit.product_ids = [];
        } else {
          this.benefit.product_ids = this.getProducts().map((item) => item.id);
        }
        setTimeout(() => {
          this.changeEvent("product_ids");
        });
      });
    },
    productIcon() {
      if (this.benefit.product_ids.length == this.getProducts().length)
        return "mdi-close-box";
      if (
        this.benefit.product_ids.length > 0 &&
        this.benefit.product_ids.length != this.getProducts().length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getIcon(type, dataField) {
      if (this.benefit[type].length == this[dataField].length)
        return "mdi-close-box";
      if (
        this.benefit[type].length > 0 &&
        this.benefit[type].length != this[dataField].length
      )
        return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    getProducts() {
      if (this.product_type.name == "Event") {
        return this.products.filter((product) =>
          this.benefit.event_ids.includes(product.event_id)
        );
      } else if (this.product_type.name == "Facility") {
        return this.products.filter((product) =>
          this.benefit.category_ids.includes(product.category_id)
        );
      } else if (this.product_type.name == "Membership") {
        return this.products.filter((product) =>
          this.benefit.membership_ids.includes(product.membership_id)
        );
      } else if (this.product_type.name == "Trainer") {
        return this.products.filter((product) =>
          this.benefit.trainer_ids.includes(product.trainer_id)
        );
      }
      if (this.product_type.name == "Academy") {
        return this.products.filter((product) =>
          this.benefit.workshop_ids.includes(product.workshop_id)
        );
      }
      return this.products;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls{
  padding-top: 0 !important;
  margin-top: 0 !important;
}

label{
  color:#565C68 !important;
  font-weight: 500;

}

@media screen and (min-width: 1264px) and (max-width: 1406px) {
  .v-input{
    font-size: 12px;
  }

}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}

</style>
